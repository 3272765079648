import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Box, Button, FormHelperText, Grid, IconButton } from '@mui/joy';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Skeleton from '@mui/joy/Skeleton';

import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { getAllowedProperties } from 'src/modules/actions';
import { getAllowedProperties as selectAllowedProperties } from 'src/modules/selectors/area.selector';
import { getSitesListFilteredByGloballySelectedSites } from 'src/modules/selectors/site';
import { Trash04Svg } from 'src/components/svgIcons';
import { IAreaFormData } from 'src/modules/types';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import FormikCreatableComboBox from 'src/components/Formik/FormikCreatableComboBox';
import { LazyComponent } from '../../../components/LazyComponent';

const rowValues = {
  fieldName: '',
  fieldValue: '',
};

const initialValues = {
  siteId: null,
  area: '',
  values: [[rowValues]],
};

interface IAreaFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IAreaFormData) => void;
  onDelete?: () => void;
  initialData?: IAreaFormData;
}

const SkeletonContent: React.FC = () => (
  <DrawerBodySectionContent>
    <DrawerBodySectionTitle>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Skeleton animation="wave" variant="text" level="h1" />
      </Box>
    </DrawerBodySectionTitle>

    {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
      <Box key={i}>
        <FormFieldContainer>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pb: 1,
            }}
          >
            <Skeleton animation="wave" variant="text" level="h3" />
            <Box sx={{ minWidth: '8px' }} />
            <Box sx={{ width: '100%' }}>
              <Skeleton animation="wave" variant="text" level="h3" />
            </Box>
          </Box>
        </FormFieldContainer>
      </Box>
    ))}
  </DrawerBodySectionContent>
);

export const AreaForm = ({
  isOpen,
  onClose,
  initialData,
  onDelete,
  onSubmit,
}: IAreaFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allowedProperties = useSelector(selectAllowedProperties);
  const sites = useSelector(getSitesListFilteredByGloballySelectedSites);

  const allowedToDeleteArea = useHasUserAccessToAction(
    manageEntitiesConfig.area.delete.id,
  );

  const schema = yup.object({
    siteId: yup.number().required(t('error.validation.required')),
    area: yup.string().required(t('error.validation.required')),
    values: yup
      .array()
      .of(
        yup.array().of(
          yup.object().shape({
            fieldName: yup.string().required(t('error.validation.required')),
            fieldValue: yup.string().required(t('error.validation.required')),
          }),
        ),
      )
      .min(1, t('error.validation.at_least_one_group')),
  });
  const areaFormik = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: false,
    initialValues: initialData ?? initialValues,
    onSubmit: (data) => {
      onSubmit(data);
      areaFormik.setValues(initialValues);
      areaFormik.setTouched({});
    },
  });

  const createAddRowHandler = (index: number) => () => {
    const updatedValues = areaFormik.values.values.map((value, i) => {
      if (i === index) {
        return [...value, rowValues];
      }

      return value;
    });

    areaFormik.setFieldValue('values', updatedValues);
  };

  const createRemoveRowHandler = (
    groupIndex: number,
    rowIndex: number,
  ) => () => {
    const updatedValues = areaFormik.values.values.map((value, i) => {
      if (i === groupIndex) {
        return value.filter((_, j) => j !== rowIndex);
      }

      return value;
    });

    areaFormik.setFieldValue('values', updatedValues);
  };

  const onAddGroup = () => {
    areaFormik.setFieldValue('values', [
      ...areaFormik.values.values,
      [rowValues],
    ]);
  };

  const createOnRemoveGroupHandler = (index: number) => () => {
    areaFormik.setFieldValue(
      'values',
      areaFormik.values.values.filter((_, i) => i !== index),
    );
  };

  const onReset = () => {
    areaFormik.resetForm();
  };

  React.useEffect(() => {
    if (areaFormik.values.siteId) {
      dispatch(getAllowedProperties(areaFormik.values.siteId));
    }
  }, [areaFormik.values.siteId, dispatch]);

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      slotProps={{ content: { sx: { minWidth: 600 } } }}
    >
      <DrawerHeader onCloseClick={onClose}>
        {t('area.update_area')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <Box sx={{ pb: 1 }}>
              <FormikCombobox
                options={sites}
                label={t('area.site_name')}
                id="siteId"
                name="siteId"
                formik={areaFormik}
              />
            </Box>
          </FormFieldContainer>

          {areaFormik.values.siteId && (
            <FormFieldContainer>
              <FormikTextField
                label={t('area.area_form.new_area')}
                id="area"
                name="area"
                formik={areaFormik}
              />
            </FormFieldContainer>
          )}

          {areaFormik.values.siteId &&
            areaFormik.values.values.map((nestedValues, i) => (
              <LazyComponent key={i} skeletonContent={<SkeletonContent />}>
                <DrawerBodySectionContent key={i}>
                  <DrawerBodySectionTitle>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {`${t('common.group')} ${i + 1}`}{' '}
                      <IconButton onClick={createOnRemoveGroupHandler(i)}>
                        <Trash04Svg />
                      </IconButton>
                    </Box>
                  </DrawerBodySectionTitle>

                  {nestedValues.map((_, j) => (
                    <Grid key={j}>
                      <FormFieldContainer>
                        <Grid
                          container
                          spacing={2}
                          sx={{ flexGrow: 1, pb: 1 }}
                          wrap="nowrap"
                          alignItems="flex-end"
                        >
                          <Grid>
                            <FormikCreatableComboBox
                              options={allowedProperties as any}
                              label={t('area.field_name')}
                              id={`values.${i}.${generateFullIdInMultiEntitiesForm(
                                'fieldName',
                                j,
                              )}`}
                              name={`values.${i}.${generateFullIdInMultiEntitiesForm(
                                'fieldName',
                                j,
                              )}`}
                              formik={areaFormik}
                            />
                          </Grid>
                          <Grid>
                            <FormikTextField
                              label={t('area.field_value')}
                              id={`values.${i}.${generateFullIdInMultiEntitiesForm(
                                'fieldValue',
                                j,
                              )}`}
                              name={`values.${i}.${generateFullIdInMultiEntitiesForm(
                                'fieldValue',
                                j,
                              )}`}
                              formik={areaFormik}
                            />
                          </Grid>

                          <Grid>
                            <IconButton
                              sx={{ alignSelf: 'end' }}
                              onClick={createRemoveRowHandler(i, j)}
                            >
                              <Trash04Svg />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </FormFieldContainer>
                    </Grid>
                  ))}

                  <FormFieldContainer>
                    <Box
                      onClick={createAddRowHandler(i)}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button variant="outlined">{t('common.add_row')}</Button>
                    </Box>
                  </FormFieldContainer>
                </DrawerBodySectionContent>
              </LazyComponent>
            ))}

          {areaFormik.values.siteId && (
            <FormFieldContainer>
              <Box
                onClick={onAddGroup}
                sx={{
                  pt: 1,
                }}
              >
                <Button fullWidth variant="outlined">
                  {t('common.add_group')}
                </Button>
                {areaFormik.errors.values &&
                  typeof areaFormik.errors.values === 'string' && (
                    <FormHelperText
                      sx={{
                        pt: '4px',
                        color: 'colors.text.text_error_primary.main',
                      }}
                    >
                      {areaFormik.errors.values}
                    </FormHelperText>
                  )}
              </Box>
            </FormFieldContainer>
          )}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onCancel={onClose}
          onDelete={allowedToDeleteArea ? onDelete : undefined}
          applyButtonType="submit"
          onApply={areaFormik.handleSubmit}
        />
      </DrawerFooter>
    </Drawer>
  );
};
