import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/joy';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { pullAt } from 'lodash';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';

import {
  useFetchActiveDepartmentsCombobox,
  useSitesOptions,
} from 'src/modules/utils';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  MUI_SIZE_12,
  MUI_SIZE_2,
  MUI_SIZE_6,
  useHasUserAccessToAction,
} from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { useProfitSettingsForm } from 'src/modules/utils/hooks/profit-settings';
import { ProfitSettingNameByAreaForm } from 'src/pages/ProfitSettings/components/ProfitSettingNameByArea/ProfitSettingNameByAreaForm';
import { IProfitSettingsModel } from 'src/modules/types/profit-settings.type';
import { FormActions } from 'src/components/Form/FormActions';
import { ProfitSettingNameByDepartmentForm } from 'src/pages/ProfitSettings/components/ProfitSettingNameByDepartment/ProfitSettingNameByDepartmentForm';
import { getDepartmentsComboboxListBySiteId } from 'src/modules/selectors/department';
import FormikTextField from '../../components/Formik/FormikTextField';

interface IProfitSettingsForm {
  isLoading: boolean;
  formik: FormikProps<IProfitSettingsModel>;
}

const initialValues = {
  siteId: NaN,
  profitNamesByArea: [],
  profitNamesByDepartment: [],
};

const ProfitSettingsForm: React.FC<IProfitSettingsForm> = ({
  formik,
  isLoading,
}) => {
  const { t } = useTranslation();

  const fetchActiveDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  React.useEffect(() => {
    fetchActiveDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const departments = useSelector(getDepartmentsComboboxListBySiteId)(
    formik.values.siteId,
  );

  const handleAddingNewRow = (
    type: 'profitNamesByArea' | 'profitNamesByDepartment',
  ) => () => {
    const current = [...formik.values[type]];

    switch (type) {
      case 'profitNamesByArea':
        current.unshift({
          area: '',
          profitName: '',
        });
        break;
      case 'profitNamesByDepartment':
        current.unshift({
          profitName: '',
          departmentId: NaN,
          departmentName: '',
        });
        break;
    }

    formik.setFieldValue(type, current);
  };

  const handleRowDeleting = (
    type: 'profitNamesByArea' | 'profitNamesByDepartment',
  ) => (rowIndex: number) => {
    const current = [...formik.values[type]];

    pullAt(current, [rowIndex]);

    formik.setFieldValue(type, current);
  };

  if (isLoading) {
    return (
      <Box sx={{ position: 'relative', minHeight: '40vh' }}>
        <MainContentLoader />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Grid container sx={{ width: '100%' }} spacing={MUI_SIZE_2}>
          <Grid xs={MUI_SIZE_12}>
            <Typography typography="text_xl" sx={{ marginBottom: 4 }}>
              {t('profit_settings.profitService')}
            </Typography>
            <FormikTextField
              label={t('profit_settings.profitService')}
              id={`profitService`}
              name={`profitService`}
              formik={formik}
            />
          </Grid>
          <Grid md={MUI_SIZE_6} xs={MUI_SIZE_12}>
            <Grid
              container
              md={MUI_SIZE_6}
              xs={MUI_SIZE_12}
              justifyContent="space-between"
            >
              <Typography typography="text_xl" sx={{ marginBottom: 4 }}>
                {t('profit_settings.profitNamesByArea')}
              </Typography>
              <IconButton onClick={handleAddingNewRow('profitNamesByArea')}>
                <AddIcon />
              </IconButton>
            </Grid>

            <ProfitSettingNameByAreaForm
              formik={formik}
              onRowDelete={handleRowDeleting('profitNamesByArea')}
            />
          </Grid>
          <Grid md={MUI_SIZE_6} xs={MUI_SIZE_12}>
            <Grid
              container
              md={MUI_SIZE_6}
              xs={MUI_SIZE_12}
              justifyContent="space-between"
            >
              <Typography typography="text_xl" sx={{ marginBottom: 4 }}>
                {t('profit_settings.profitNamesByDepartment')}
              </Typography>
              <IconButton
                onClick={handleAddingNewRow('profitNamesByDepartment')}
              >
                <AddIcon />
              </IconButton>
            </Grid>

            <ProfitSettingNameByDepartmentForm
              formik={formik}
              departments={departments}
              onRowDelete={handleRowDeleting('profitNamesByDepartment')}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const ProfitSettings: React.FC = () => {
  const { t } = useTranslation();
  const [site, setSite] = React.useState<ComboBoxOption>({
    id: NaN,
    name: '',
  });

  const {
    id,
    isLoading,
    sendToServer,
    profitService,
    profitNamesByArea,
    profitNamesByDepartment,
  } = useProfitSettingsForm(site.id);

  const hasUserAccessToUpsertProfitSettings = useHasUserAccessToAction(
    manageEntitiesConfig.profit_settings.upsert.id,
  );

  const schema = yup.object({
    siteId: yup.number().required(t('error.validation.required')),
    profitNamesByArea: yup
      .array()
      .of(
        yup.object().shape({
          area: yup.string().required(t('error.validation.required')),
          profitName: yup.string().required(t('error.validation.required')),
        }),
      )
      .min(1, t('error.validation.at_least_one_group')),
    profitNamesByDepartment: yup
      .array()
      .of(
        yup.object().shape({
          profitName: yup.string().required(t('error.validation.required')),
          departmentId: yup.number().required(t('error.validation.required')),
          departmentName: yup.string().required(t('error.validation.required')),
        }),
      )
      .min(1, t('error.validation.at_least_one_group')),
  });

  const formik = useFormik<IProfitSettingsModel>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: false,
    initialValues: id
      ? {
          siteId: site.id,
          profitService,
          profitNamesByArea,
          profitNamesByDepartment,
        }
      : initialValues,
    onSubmit: (data) => {
      sendToServer(data);
    },
  });

  const siteOptions = useSitesOptions();

  const handleSiteChange = React.useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: ComboBoxOption | null,
    ) => value && setSite(value as ComboBoxOption),
    [setSite],
  );

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <FormFieldContainer>
          <Typography typography="text_xl">
            {t('main_menu.profit_settings')}
          </Typography>
          <Box sx={{ marginBottom: '1rem' }}>
            <ComboBox
              id="site"
              placeholder={t('profit_settings.site')}
              options={siteOptions}
              value={site}
              onChange={handleSiteChange}
            />
          </Box>

          <Box>
            {Boolean(site?.id) && (
              <>
                <ProfitSettingsForm formik={formik} isLoading={isLoading} />
              </>
            )}
          </Box>

          {hasUserAccessToUpsertProfitSettings && (
            <FormActions
              onClick={formik.handleSubmit}
              submitBtnTitle={t('common.update')}
              submitBtnDisabled={!id || (!formik.dirty && !formik.isValid)}
              isLoading={isLoading}
            />
          )}
        </FormFieldContainer>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
