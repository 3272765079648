import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  IFilterWhere,
  IListWithSummary,
  IProfitModel,
} from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';

export const useProfitTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      year: {
        value: '',
        property: 'year',
        operator: 'eq' as const,
      },
      week: {
        value: '',
        property: 'week',
        operator: 'eq' as const,
      },
      cpuArea: {
        value: '',
        property: 'cpuArea',
        operator: 'eq' as const,
      },
    };
  }, []);
};

export const useProfitComposeWhere = (where: Partial<IProfitModel>) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      ...(where.year
        ? {
            year: {
              eq: where.year,
            },
          }
        : {}),
      ...(where.week
        ? {
            week: {
              eq: where.week,
            },
          }
        : {}),
      ...(where.cpuArea
        ? {
            cpuArea: {
              eq: where.cpuArea,
            },
          }
        : {}),
    }),
    [selectedSites, where.siteId, where.year, where.week, where.cpuArea],
  );
};

export const useProfit = (reportUrl: string, where: IFilterWhere) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('profit.siteName'),
      },
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('profit.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('profit.week'),
      },
      {
        id: 'cpuArea',
        orderConfig: { orderBy: 'cpuArea' },
        label: t('profit.cpuArea'),
      },
      {
        id: 'cpuRevenue',
        orderConfig: { orderBy: 'cpuRevenue' },
        label: t('profit.cpuRevenue'),
      },
      {
        id: 'cpuCosts',
        orderConfig: { orderBy: 'cpuCosts' },
        label: t('profit.cpuCosts'),
      },
      {
        id: 'profitByArea',
        orderConfig: { orderBy: 'profitByArea' },
        label: t('profit.profitByArea'),
      },
    ],
    [t],
  );

  const report = useBaseReport<IListWithSummary<IProfitModel>>({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'yearWeek',
    initialData: {
      items: [],
      summary: [],
    },
  });

  return React.useMemo(
    () => ({
      ...report,
      data: {
        ...report.data,
        summary: report.data.summary,
        items: report.data.items.map((item: IProfitModel) => ({
          ...item,
          profitByArea: (
            <Typography
              sx={{
                color: item.color === 'red' ? 'red' : 'inherit',
              }}
            >
              {item.profitByArea}
            </Typography>
          ),
        })),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report],
  );
};
