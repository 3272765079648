import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getTerminationReasonsByIds,
  getTerminationReasonsComboboxList,
} from '../../selectors/terminationReason';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getTerminationReasonComboboxListRequest,
  getTerminationReasonListRequest,
} from '../../actions/terminationReason';
import { useSiteRelationInclusion } from '.';
import { defaultOrderDetails } from '../helpers/filter';

export const terminationReasonsInclusions = [
  {
    relation: 'site',
  },
];

const useTerminationReasonComboboxFilter = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          name: true,
          siteId: true,
        },
        include: [siteRelationInclusion],
      },
    }),
    [siteRelationInclusion],
  );
};

/**
 * A custom hook to fetch terminationReasons from store if they exist otherwise to make a request to fetch needed terminationReasons from
 * the server
 */
export const useFetchTerminationReasonsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch terminationReasons list from store
  const terminationReasons = useSelector(
    (state) => getTerminationReasonsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(terminationReasons)) {
      dispatcher(
        getTerminationReasonListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: terminationReasonsInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch terminationReasons combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchTerminationReasonsCombobox = (
  nonArchivedOnly?: boolean,
) => {
  const dispatcher = useDispatch();
  const terminationReasonComboboxFilter = useTerminationReasonComboboxFilter();

  const dispatchGetTerminationReasonComboboxListRequest = React.useCallback(() => {
    dispatcher(
      getTerminationReasonComboboxListRequest({
        filter: {
          ...terminationReasonComboboxFilter.filter,
          ...(nonArchivedOnly
            ? {
                where: {
                  archivedAt: { eq: null },
                },
              }
            : {}),
        },
      }),
    );
  }, [dispatcher, terminationReasonComboboxFilter.filter, nonArchivedOnly]);

  React.useEffect(() => {
    dispatchGetTerminationReasonComboboxListRequest();
  }, [dispatchGetTerminationReasonComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getTerminationReasonsComboboxList);
  return () => {
    if (isEmpty(combobox)) {
      dispatchGetTerminationReasonComboboxListRequest();
    }
  };
};

export const useTerminationReasonsDefaultFilters = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteInclusion],
    }),
    [siteInclusion],
  );
};
