import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Grid } from '@mui/joy';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/joy/Skeleton';

import {
  MUI_SIZE_1,
  MUI_SIZE_12,
  MUI_SIZE_2,
  MUI_SIZE_5,
  MUI_SIZE_6,
} from 'src/config';
import { IProfitSettingsModel } from 'src/modules/types/profit-settings.type';
import { LazyComponent } from 'src/components/LazyComponent';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { ComboBoxOption } from 'src/components/ComboBox';
import { DepartmentComboboxOption } from 'src/modules/types';

interface ProfitSettingNameByDepartmentFieldProps {
  index: number;
  departments: DepartmentComboboxOption[];
  formik: FormikProps<IProfitSettingsModel>;
  onRowDelete(index: number): void;
}

const SkeletonContent: React.FC = () => (
  <Grid
    container
    spacing={MUI_SIZE_2}
    alignItems="flex-start"
    sx={{ marginBottom: '1rem' }}
  >
    <Grid md={MUI_SIZE_5} xs={MUI_SIZE_12}>
      <Skeleton animation="wave" variant="text" level="h1" />
    </Grid>
    <Grid md={MUI_SIZE_6} xs={MUI_SIZE_12}>
      <Skeleton animation="wave" variant="text" level="h1" />
    </Grid>
    <Grid container md={MUI_SIZE_1} xs={MUI_SIZE_12} alignItems="center">
      <Skeleton animation="wave" variant="text" level="h1" />
    </Grid>
  </Grid>
);

export const ProfitSettingNameByDepartmentField: React.FC<ProfitSettingNameByDepartmentFieldProps> = ({
  formik,
  index,
  departments,
  onRowDelete,
}) => {
  const { t } = useTranslation();

  const handleChange = (
    event: React.ChangeEvent<any>,
    value: ComboBoxOption<number> | null,
  ) => {
    if (value) {
      formik.setFieldValue(
        `profitNamesByDepartment.${index}.departmentName`,
        value.name,
      );
    }
  };

  return (
    <LazyComponent skeletonContent={<SkeletonContent />}>
      <Grid
        container
        spacing={MUI_SIZE_2}
        alignItems="center"
        sx={{ marginBottom: '1rem' }}
      >
        <Grid md={MUI_SIZE_5} xs={MUI_SIZE_12}>
          <FormikTextField
            label={t('profit_settings.profitName')}
            id={`profitNamesByDepartment.${index}.profitName`}
            name={`profitNamesByDepartment.${index}.profitName`}
            formik={formik}
          />
        </Grid>
        <Grid
          md={MUI_SIZE_6}
          xs={MUI_SIZE_12}
          justifyContent="space-between"
          alignItems="self-end"
        >
          <FormikCombobox
            id={`profitNamesByDepartment.${index}.departmentId`}
            label={t('profit_settings.department')}
            placeholder={t('profit_settings.department')}
            onChange={handleChange}
            options={departments}
            formik={formik}
          />
        </Grid>
        <Grid md={MUI_SIZE_1} xs={MUI_SIZE_12}>
          <IconButton onClick={() => onRowDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </LazyComponent>
  );
};
