export type ClientsKeysNames = keyof typeof clients;
export type ClientKey = typeof clients[ClientsKeysNames];

export const clients = {
  barrett: 'barrett',
  brookfield: 'brookfield',
  cardinalHealth: 'cardinal-health',
  carparts: 'carparts',
  dorman: 'dorman',
  levis: 'levis',
  optoro: 'optoro',
  expeditors: 'expeditors',
  mcEntire: 'mc-entire',
  target: 'target',
  nfi: 'nfi',
  sony: 'sony',
  williamsSonoma: 'williams-sonoma',
  perryEllis: 'perry-ellis',
  schneider: 'schneider',
  taylorFarms: 'taylor-farms',
  rebelStork: 'Rebelstork',
} as const;

export const clientKeysWithMonthInvoiceEnabled: Array<ClientsKeysNames> = [
  'target',
];

export const clientsWithoutEmployees: ClientsKeysNames[] = [
  'barrett',
  'brookfield',
];
