import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FilterValue, FilterValueDescriptor } from 'src/modules/types/table';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';

interface IProfitFilterFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(
    filtersData: Record<string, FilterValue>,
  ): Record<string, FilterValueDescriptor>;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const ProfitFilter: React.FC<IProfitFilterFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <SitesComboBoxFilter
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('employees.terminated.site'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'week',
            labelPrefix: t('profit.week'),
          })}
          {...getFilterCommonPropsByFilterName('week')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'year',
            labelPrefix: t('profit.year'),
          })}
          {...getFilterCommonPropsByFilterName('year')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'cpuArea',
            labelPrefix: t('profit.cpuArea'),
          })}
          {...getFilterCommonPropsByFilterName('cpuArea')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
