import React from 'react';
import { Grid } from '@mui/joy';
import { FormikProps } from 'formik';

import { MUI_SIZE_12, MUI_SIZE_2 } from 'src/config';
import { ProfitSettingNameByDepartmentField } from './ProfitSettingNameByDepartmentField';
import { IProfitSettingsModel } from 'src/modules/types/profit-settings.type';
import { DepartmentComboboxOption } from 'src/modules/types';

interface ProfitSettingNameByDepartmentFormProps {
  formik: FormikProps<IProfitSettingsModel>;
  departments: DepartmentComboboxOption[];
  onRowDelete(index: number): void;
}

export const ProfitSettingNameByDepartmentForm: React.FC<ProfitSettingNameByDepartmentFormProps> = ({
  formik,
  departments,
  onRowDelete,
}) => {
  const rows = React.useMemo(
    () =>
      (formik.values.profitNamesByDepartment ?? []).map((_, index) => (
        <Grid key={`profitNamesByDepartment-${index}`} xs={MUI_SIZE_12}>
          <ProfitSettingNameByDepartmentField
            formik={formik}
            departments={departments}
            index={index}
            onRowDelete={onRowDelete}
          />
        </Grid>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [departments, formik.values.profitNamesByDepartment],
  );

  return (
    <>
      <Grid
        container
        spacing={MUI_SIZE_2}
        alignItems="flex-start"
        sx={{ marginBottom: '1rem' }}
      >
        {rows}
      </Grid>
    </>
  );
};
