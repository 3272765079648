import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterPanel, IFilterPanelOption } from 'src/components/FilterPanel';
import { FilterPanelShiftsCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelShiftsCombobox';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';

export interface IHrDashboardFilters {
  siteId?: number;
  shiftId?: number;
}

type IHRDashboardFilterPanelProps = {
  filterInitialData: IHrDashboardFilters;
  onApplyFilters: (filters: IHrDashboardFilters) => void;
  onRemoveFilters: () => void;
};

export const HRDashboardFilterPanel = ({
  onApplyFilters,
  onRemoveFilters,
  filterInitialData,
}: IHRDashboardFilterPanelProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = React.useState<IHrDashboardFilters>(
    filterInitialData,
  );

  const filterOptionsConfig: Array<IFilterPanelOption> = React.useMemo(
    () => [
      {
        id: 'siteId' as const,
        label: t('productions_uph_reports.site.name'),
        isPermanent: true,
      },
      {
        id: 'shiftId' as const,
        label: t('productions_uph_reports.shift.name'),
      },
    ],
    [t],
  );

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<IHrDashboardFilters>(
      (obj, filterId) => {
        obj[filterId] = filters[filterId];

        return obj;
      },
      {},
    );

    onApplyFilters(appliedFiltersData);
  };

  const onRemoveAllOptions = () => {
    setFilters(filterInitialData);

    onRemoveFilters();
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    if (filter.id === 'siteId') {
      return (
        <FilterPanelSitesCombobox
          disableClearable={true as any}
          excludeTestSites
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters.siteId ?? null}
          onChange={(_, value) => {
            setFilters((prev: any) => ({
              ...prev,
              shiftId: undefined,
              [filter.id]: value?.id,
            }));
          }}
        />
      );
    }
    if (filter.id === 'shiftId') {
      return (
        <FilterPanelShiftsCombobox
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          siteId={filters.siteId}
          value={filters.shiftId ?? null}
          onChange={(_, value) => {
            setFilters((prev: any) => ({
              ...prev,
              [filter.id]: value?.id,
            }));
          }}
        />
      );
    }

    return null;
  };

  React.useEffect(() => {
    setFilters(filterInitialData);
  }, [filterInitialData]);

  return (
    <FilterPanel
      renderFilter={renderFilter}
      filterOptionsConfig={filterOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllOptions}
    />
  );
};
