import React from 'react';
import { find, get, isEqual } from 'lodash';
import ComboBox, { ComboBoxOption, IComboboxProps } from '../ComboBox';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';

export interface IFormikComboboxProps extends IComboboxProps {
  id: string;
  formik: AnyObject;
  errorMode?: ErrorMode;
  setToUndefinedOnFieldClear?: boolean;
  onChange?: (
    event: React.ChangeEvent<any>,
    value: ComboBoxOption | null,
  ) => void;
}

function FormikCombobox(props: IFormikComboboxProps) {
  const {
    id,
    formik,
    options,
    onChange,
    setToUndefinedOnFieldClear = false,
    errorMode = 'onFormSubmit',
    ...restProps
  } = props;

  const [optionsMap, setOptionsMap] = React.useState<
    Map<number, ComboBoxOption<number>>
  >(new Map());

  React.useEffect(() => {
    options.forEach((option) => {
      setOptionsMap((prevState) => {
        const newMap = new Map(prevState);

        newMap.set(option.id, option);

        return newMap;
      });
    });
  }, [options]);

  // This is a fix for performance issue, if you need to get rid of it
  // please try select a lot of users ~15 and open update form without
  // it to verify that new logic not break the issue with performance
  const isInitialRendering = React.useRef(true);

  // handle combobox changing
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    const defaultFieldValue = setToUndefinedOnFieldClear ? undefined : null;

    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, value ? value.id : defaultFieldValue);

    if (onChange) {
      onChange(event, value ?? null);
    }
  };

  const option = React.useMemo(() => {
    if (formik.values[id] && typeof formik.values[id] === 'object') {
      if (formik.values[id].id) {
        return optionsMap.get(formik.values[id].id);
      } else {
        // In Filter component in EnhancedTable initial filter data will be like that: {<operator>: <value>}
        return optionsMap.get(Object.values(formik.values[id])[0] as number);
      }
    }

    return optionsMap.get(get(formik.values, id));
  }, [formik.values, id, optionsMap]);

  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  React.useEffect(() => {
    if (isInitialRendering.current) {
      return;
    }
    const defaultFieldValue = setToUndefinedOnFieldClear ? undefined : null;
    // get selected value
    const value = get(formik.values, id);
    // get initial value
    const initialValue = get(formik.initialValues, id);
    // get selected option
    const option = find(options, (o) => o.id === value);
    // get initial option
    const initialOption = find(options, (o) => o.id === initialValue);

    // formik.setFieldError(id, undefined); // causes too many re-renders
    formik.setFieldValue(
      id,
      option?.id ?? initialOption?.id ?? defaultFieldValue,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (isInitialRendering.current) {
      return;
    }
    // trigger form validation against current field
    // after it has been changed
    formik.validateField(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(formik.values, id)]);

  React.useEffect(() => {
    isInitialRendering.current = false;
  }, []);

  return (
    <ComboBox
      {...restProps}
      id={id}
      options={options}
      value={option || null}
      onChange={handleChange}
      error={showError}
      helperText={showError && error}
    />
  );
}

export default React.memo(FormikCombobox, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.placeholder === cur.placeholder &&
    prev.label === cur.label &&
    prev.errorMode === cur.errorMode &&
    prev.required === cur.required &&
    prev.disabled === cur.disabled &&
    isEqual(prev.options, cur.options) &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
}) as typeof FormikCombobox;
