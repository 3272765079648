import React from 'react';
import { Grid } from '@mui/joy';
import { FormikProps } from 'formik';

import { MUI_SIZE_12, MUI_SIZE_2 } from 'src/config';
import { ProfitSettingNameByAreaField } from './ProfitSettingNameByAreaField';
import { IProfitSettingsModel } from 'src/modules/types/profit-settings.type';

interface ProfitSettingNameByAreaFormProps {
  formik: FormikProps<IProfitSettingsModel>;
  onRowDelete(index: number): void;
}

export const ProfitSettingNameByAreaForm: React.FC<ProfitSettingNameByAreaFormProps> = ({
  formik,
  onRowDelete,
}) => {
  const rows = React.useMemo(
    () =>
      (formik.values.profitNamesByArea ?? []).map((_, index) => (
        <Grid key={`profitNamesByArea-${index}`} xs={MUI_SIZE_12}>
          <ProfitSettingNameByAreaField
            formik={formik}
            index={index}
            onRowDelete={onRowDelete}
          />
        </Grid>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.profitNamesByArea],
  );

  return (
    <>
      <Grid
        container
        spacing={MUI_SIZE_2}
        alignItems="flex-start"
        sx={{ marginBottom: '1rem' }}
      >
        {rows}
      </Grid>
    </>
  );
};
