import React from 'react';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import {
  Archive,
  Edit02Svg,
  Share01Svg,
  Trash04Svg,
} from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  exportTerminationReasonRequest,
  getTerminationReasonCountRequest,
  getTerminationReasonListRequest,
  deleteTerminationReasonRequest,
  archiveTerminationReasonRequest,
  unarchiveTerminationReasonRequest,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import { useCreateExportProps } from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useFilterFieldsDataWithQueryParamsSynchronization,
  useTableData,
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import {
  getIsTerminationReasonLoading,
  getTerminationReasonCount,
  getTerminationReasonTableComputed,
} from 'src/modules/selectors/terminationReason';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { CreateTerminationReason } from './TerminationReasonsForms/CreateTerminationReason';
import { UpdateTerminationReasons } from './TerminationReasonsForms/UpdateTerminationReasons';
import { ActionConfirmation } from 'src/components/ActionConfirmation';

export const TerminationReasonsList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);

  const [isConfirmArchiveOpened, setIsConfirmArchiveOpened] = React.useState<
    'archive' | 'unarchive' | null
  >(null);

  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const exportProps = useCreateExportProps(exportTerminationReasonRequest);

  const isDataLoading = useSelector(getIsTerminationReasonLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.terminationReason.create.id,
  );

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.terminationReason.delete.id,
  );

  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.terminationReason.update.id,
  );

  const allowedToArchive = useHasUserAccessToAction(
    manageEntitiesConfig.terminationReason.archive.id,
  );

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('terminationReasons.name'),
      },
      {
        id: 'site.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'],
        },
        label: t('terminationReasons.site'),
      },
      {
        id: 'voluntary',
        orderConfig: { orderBy: 'voluntary' },
        label: t('terminationReasons.voluntary'),
      },
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
      {
        id: 'archivedAt',
        orderConfig: {
          orderBy: 'archivedAt',
        },
        label: t('terminationReasons.archivedAt'),
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      voluntary: {
        value: '',
        property: 'voluntary',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const {
    filterFields,
    getLabel,
    clearFilters,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getTerminationReasonTableComputed,
    selectCount: getTerminationReasonCount,
    getEntitiesRequest: getTerminationReasonListRequest,
    getEntitiesCountRequest: getTerminationReasonCountRequest,
  });

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const handleArchiveModalOpenning = (
    action: 'archive' | 'unarchive',
  ) => () => {
    setIsConfirmArchiveOpened(action);
  };

  const archiveActionTitle = React.useMemo(() => {
    switch (isConfirmArchiveOpened) {
      case 'archive':
        return t('terminationReasons.archive_confirm');
      case 'unarchive':
        return t('terminationReasons.unarchive_confirm');
      default:
        return '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmArchiveOpened]);

  const handleArchive = () => {
    const payload = {
      data: selectedEntitiesIds,
      filters: {
        list: {
          include: filter.include,
          order: filter.order,
          limit: DEFAULT_LIMIT,
          offset: DEFAULT_PAGE,
        },
        count: { include: filter.include },
      },
    };
    switch (isConfirmArchiveOpened) {
      case 'archive':
        dispatch(archiveTerminationReasonRequest(payload));
        break;
      case 'unarchive':
        dispatch(unarchiveTerminationReasonRequest(payload));
        break;
    }

    setIsConfirmArchiveOpened(null);
    setSelectedEntitiesIds([]);
  };

  const actionsConfiguration = [];
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  if (allowedToArchive) {
    actionsConfiguration.push({
      tooltip: t('common.move_to_archive'),
      icon: <Archive />,
      onClick: handleArchiveModalOpenning('archive'),
    });
    actionsConfiguration.push({
      tooltip: t('common.retrieve_from_archive'),
      icon: <Share01Svg />,
      onClick: handleArchiveModalOpenning('unarchive'),
    });
  }

  const onDelete = () => {
    clearFilters();

    dispatch(
      deleteTerminationReasonRequest({
        data: selectedEntitiesIds,
        filters: {
          list: {
            include: filter.include,
            order: filter.order,
            limit: DEFAULT_LIMIT,
            offset: DEFAULT_PAGE,
          },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>{t('terminationReasons.table_name')}</TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <SitesComboBoxFilter
                    label={getLabel({
                      filterName: 'siteId',
                      labelPrefix: t('terminationReasons.site'),
                    })}
                    {...getFilterCommonPropsByFilterName('siteId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'name',
                      labelPrefix: t('terminationReasons.name'),
                    })}
                    {...getFilterCommonPropsByFilterName('name')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <CheckboxFilter
                    label={getLabel({
                      filterName: 'voluntary',
                      labelPrefix: t('terminationReasons.voluntary'),
                    })}
                    {...getFilterCommonPropsByFilterName('voluntary')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={filter}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />

              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/termination_reason"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.TerminationReason}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={(_, page) => setPage(page)}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <CreateTerminationReason
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <UpdateTerminationReasons
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
        terminationReasonsUpdateIds={selectedEntitiesIds}
      />

      <ActionConfirmation
        type="success"
        open={!!isConfirmArchiveOpened}
        onCancel={() => setIsConfirmArchiveOpened(null)}
        onOk={handleArchive}
      >
        {archiveActionTitle}
      </ActionConfirmation>
    </PageContentWithTopToolbar>
  );
};
