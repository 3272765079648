import {
  IdsArray,
  IStoreState,
  IUpdateTerminationReason,
  TerminationReasonComboboxOption,
} from '../types';
import { createSelector } from 'reselect';
import { filter, isEmpty, map, memoize, omit, reduce } from 'lodash';
import { filterListBySiteId } from '../utils/helpers/common';
import { i18n } from '../utils';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from '../utils/dateWrapper';

export const getTerminationReasonRefreshKey = (state: IStoreState) =>
  state.terminationReason.refreshKey;

export const getTerminationReasonData = (state: IStoreState) =>
  state.terminationReason;

/**
 * Get server error
 * @param terminationReason - State terminationReason
 */
export const getServerError = ({ terminationReason }: IStoreState) =>
  terminationReason.error;

/**
 * Get terminationReason list
 * @param terminationReason - State terminationReason
 */
export const getTerminationReasonList = ({ terminationReason }: IStoreState) =>
  terminationReason.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param terminationReason - State terminationReason
 */
export const getTerminationReasonTable = createSelector(
  getTerminationReasonList,
  (terminationReason) =>
    map(filter(terminationReason, (s) => !isEmpty(s.site))),
);

export const getTerminationReasonTableComputed = createSelector(
  getTerminationReasonTable,
  (list) =>
    map(list, (item) => ({
      ...item,
      ...(item.archivedAt
        ? {
            archivedAt: composeDate(
              item.archivedAt,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            ),
          }
        : {}),
      voluntary: item.voluntary
        ? i18n.t('terminationReasons.voluntary_yes')
        : i18n.t('terminationReasons.voluntary_no'),
    })),
);

/**
 * Get terminationReasons by array of ids
 * @param terminationReason - State terminationReason
 */
export const getTerminationReasonsByIds = createSelector(
  getTerminationReasonList,
  (terminationReasons) => (ids: IdsArray) => {
    const initial: IUpdateTerminationReason[] = [];
    return reduce(
      terminationReasons,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push({
            ...omit(cur, ['site']),
            voluntary: !!cur.voluntary,
          });
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get terminationReason count
 * @param terminationReason - State terminationReason
 */
export const getTerminationReasonCount = ({ terminationReason }: IStoreState) =>
  terminationReason.count;

/**
 * Get combobox list array
 */
export const getTerminationReasonsComboboxListArray = ({
  terminationReason,
}: IStoreState) => terminationReason.comboboxList;

/**
 * Get combobox list
 */
export const getTerminationReasonsComboboxList = ({
  terminationReason,
}: IStoreState) =>
  map(terminationReason.comboboxList, (terminationReasonItem) => ({
    id: terminationReasonItem.id,
    name: terminationReasonItem.name,
    siteId: terminationReasonItem.siteId,
    site: terminationReasonItem.site,
  })) as TerminationReasonComboboxOption[];

export const getTerminationReasonsComboboxListWithNameAsId = ({
  terminationReason,
}: IStoreState) =>
  map(terminationReason.comboboxList, (terminationReasonItem) => ({
    id: terminationReasonItem['name'] as any,
    name: terminationReasonItem.name,
    siteId: terminationReasonItem.siteId,
  })) as TerminationReasonComboboxOption[];

/**
 * Get combobox options by siteId
 */
export const getTerminationReasonComboboxListBySiteId = createSelector(
  getTerminationReasonsComboboxList,
  (terminationReasons) =>
    memoize((siteId: number) => filterListBySiteId(terminationReasons, siteId)),
);

export const getIsTerminationReasonLoading = createSelector(
  getTerminationReasonData,
  (data) =>
    data.isTerminationReasonDataCountLoading ||
    data.isTerminationReasonDataLoading ||
    data.isTerminationReasonDataManagingLoading,
);
