import React from 'react';
import { useTranslation } from 'react-i18next';

import { Report, ReportPage } from 'src/components/ReportPage';
import { clients } from 'src/modules/constants';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import {
  IRebelStorkReportsFilterPanelFilters,
  RebelStorkReportsFilterPanel,
} from './RebelStorkReportsFilterPanel';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportPageExport } from 'src/components/ReportPage/ReportPageExport';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { WeeklyCostPlus } from './WeeklyCostPlus';

const reportsDefaultFilters: IRebelStorkReportsFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
  shiftId: undefined,
  departmentId: undefined,
  employeeId: undefined,
};

const client = clients.rebelStork;

export const RebelStorkReports = () => {
  const { t } = useTranslation();

  const reportProps = useReportsProps(client);

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IRebelStorkReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('cost_plus_weekly.site'),
        isPreselected: true,
      },
      { id: 'year', label: t('cost_plus_weekly.year') },
      { id: 'week', label: t('cost_plus_weekly.week') },
      { id: 'shiftId', label: t('cost_plus_weekly.shift') },
      { id: 'departmentId', label: t('cost_plus_weekly.department') },
      { id: 'employeeId', label: t('productions_uph_reports.employee') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IRebelStorkReportsFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={client}>
        <ReportTableToolbar reportName={t('main_menu.rebelstork.main')}>
          <ReportPageActionsGroupContainer>
            <ReportPageActionsGroupItem>
              <SelectHeadCells />
            </ReportPageActionsGroupItem>
          </ReportPageActionsGroupContainer>

          <ReportPageActionsGroupContainer>
            <ReportPageActionsGroupItem>
              <ReportPageExport reportProps={reportProps} />
            </ReportPageActionsGroupItem>
          </ReportPageActionsGroupContainer>
        </ReportTableToolbar>
        <ReportPageItemContainer>
          <RebelStorkReportsFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <Report {...reportProps.weeklyCostPlus}>
          <ReportPageItemContainer fullHeight>
            <WeeklyCostPlus
              siteId={appliedFilters.siteId}
              shiftId={appliedFilters.shiftId}
              departmentId={appliedFilters.departmentId}
              employeeId={appliedFilters.employeeId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
