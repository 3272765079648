import React, { useState, useEffect, useRef } from 'react';

interface LazyComponentProps {
  skeletonContent: React.ReactNode;
}

export const LazyComponent: React.FC<LazyComponentProps> = ({
  children,
  skeletonContent,
}) => {
  const ref = useRef<null>();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) setIsVisible(true);
    });

    observer.observe(ref.current!);
    return () => observer.disconnect();
  }, []);

  return (
    <div ref={ref as React.RefObject<HTMLDivElement>}>
      {!isVisible && skeletonContent}
      {isVisible && children}
    </div>
  );
};
