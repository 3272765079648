import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getQueryKey } from 'src/modules/utils/helpers/reactQuery.helpers';
import Api from 'src/modules/utils/Api';
import { paths } from 'src/config';
import { IProfitSettingsModel } from 'src/modules/types/profit-settings.type';

const getSettingsFetchParameters = (siteId: number) => {
  const queryKey = getQueryKey(paths.PROFIT_SETTINGS, {
    filter: { where: { siteId } },
  });

  return { queryKey };
};

export const useProfitSettingsData = (siteId: number) => {
  const { queryKey } = getSettingsFetchParameters(siteId);

  const { data, isLoading, error } = useQuery(
    queryKey,
    () => Api.ProfitSettings.getSettings(siteId),
    { enabled: Boolean(siteId), retry: false, cacheTime: 0 },
  );

  const {
    id,
    profitService,
    profitNamesByArea,
    profitNamesByDepartment,
  } = React.useMemo<IProfitSettingsModel>(
    () => (data?.length ? data[0] : ({} as IProfitSettingsModel)),
    [data],
  );

  return {
    id,
    error,
    siteId,
    isLoading,
    profitService,
    profitNamesByArea,
    profitNamesByDepartment,
  };
};

export const useProfitSettings = (siteId: number) => {
  const {
    id,
    isLoading,
    profitService,
    profitNamesByArea,
    profitNamesByDepartment,
  } = useProfitSettingsData(siteId);

  return {
    id,
    siteId,
    isLoading,
    profitService,
    profitNamesByArea,
    profitNamesByDepartment,
  };
};

export const useProfitSettingsForm = (siteId: number) => {
  const { queryKey } = getSettingsFetchParameters(siteId);
  const queryClient = useQueryClient();

  // get data
  const {
    id,
    isLoading,
    profitService,
    profitNamesByArea,
    profitNamesByDepartment,
  } = useProfitSettings(siteId);

  // get mutation functions for sending data to server
  const { mutate: upsertSettings, isLoading: upsertLoading } = useMutation(
    (data: IProfitSettingsModel) => Api.ProfitSettings.createSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  // submit function
  const sendToServer = (settings: IProfitSettingsModel) => {
    upsertSettings(settings);
  };

  return {
    id,
    siteId,
    sendToServer,
    profitService,
    profitNamesByArea,
    profitNamesByDepartment,
    isLoading: isLoading || upsertLoading,
  };
};
